<template>
    <div class="tech-warp">
      <div class="ab-lunbo"> <img src="../../../assets/images/back01.png"></div>
       <div class="ab-text">
            <div class="ab-title">
                <p>—— 组织体系 ——</p>
            </div>
            <div class="ab-desc">
                <p>
                  产教融合整体业务体系-“1521”
                </p>
            </div>
       </div>
      <div class="yz-img-con">
         <img src="../../../assets/images/bus01.png">
      </div>
    </div>
</template>
<script>
export default {
  data(){
    return{

    }
  }
}
</script>
<style lang="less" >
   .yz-img-con{
      width:1200px;
      margin:0 auto;
     img{
       width: auto;
       object-fit:none;
     }
   }
</style>
